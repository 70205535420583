<template>
  <v-container>
    <v-row class="text-center aligh-center">
      <v-card class="mx-auto" width="90%">
        <v-row class="align-center" style="height: 100px">
          <v-spacer />
          <!--v-img width="10%"  src="CCSP_Y.jpeg"/-->
          <v-spacer />
          <h1>{{ origin.name }}分数分布</h1>
          <v-spacer />
          <!--v-img width="10%"  class="mx-auto" src="MIAO.jpeg"/-->
          <v-spacer />
        </v-row>

        <!--v-card-text><v-text-field v-model="searchinfo" label="搜索"></v-text-field></v-card-text-->
        <!--v-btn @click.stop="(staticData.series[0].label.show=!staticData.series[0].label.show)&updateData()"><v-app-bar-nav-icon />P1</v-btn>
        <v-btn @click.stop="(staticData.series[1].label.show=!staticData.series[1].label.show)&updateData()"><v-app-bar-nav-icon />P2</v-btn>
        <v-btn @click.stop="(staticData.series[2].label.show=!staticData.series[2].label.show)&updateData()"><v-app-bar-nav-icon />P3</v-btn>
        <v-btn @click.stop="(staticData.series[3].label.show=!staticData.series[3].label.show)&updateData()"><v-app-bar-nav-icon />P4</v-btn>
        <v-btn @click.stop="(staticData.series[4].label.show=!staticData.series[4].label.show)&updateData()"><v-app-bar-nav-icon />P5</v-btn>
        <v-btn @click.stop="(staticData.series[5].label.show=!staticData.series[5].label.show)&updateData()"><v-app-bar-nav-icon />总分</v-btn-->
        <div
          class="echart"
          id="scoreStatistics"
          :style="scoreStatisticsStyle"
        ></div>

        <v-card-actions>
          <!--v-btn color="primary"  @click="search()">刷新</v-btn-->
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Statistics",
  props: ["origin"],
  data: () => ({
    staticData: {},
    scoreStatisticsStyle: { float: "left", width: "100%", height: "700px" },
    searchinfo: "",
    totalDesserts: 1041,
    loading: true,
    options: {},
    headers: [
      {
        text: "排名",
        align: "start",
        sortable: false,
        value: "rank",
      },
      { text: "姓名", value: "realname" },
      // { text: "单位", value: "school" },
      { text: "总分", value: "total_score" },
      { text: "选择题", value: "p1" },
      { text: "判断题", value: "p2" },
      { text: "编程题1", value: "p3" },
      { text: "编程题2", value: "p4" },
      // { text: "第一题", value: "p1" },
      // { text: "第二题", value: "p2" },
      // { text: "第三题", value: "p3" },
      // { text: "第四题", value: "p4" },
      // { text: "第五题", value: "p5" },
    ],
    desserts: [],
  }),
  mounted: async function () {
    // await this.initSearch();
    await this.getDataFromApi();
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    origin: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const password =
        decodeURIComponent(
          (new RegExp("[?|&]" + "password" + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || ["", ""])[1].replace(/\+/g, "%20")
        ) || null;

      const q_string =
        "size=" +
        String(-1) +
        "&page=" +
        String(1) +
        "&origin=" +
        String(this.origin.id);
      var that = this;
      this.staticData = {
        xAxis: {
          name: "人数",
          data: [],
          /*
          name: "分数区间",
          data: ["0%","(0%,20%]","(20%,40%]","(40%,60%]","(60%,80%]","(80%,100%)","100%"]*/
        },
        // 图例
        legend: {
          data: ["选择题", "判断题", "编程题1", "编程题2", "总分"],
          // data: ["第一题", "第二题", "第三题", "第四题", "第五题", "总分"],
          top: "bottom",
        },
        yAxis: { name: "分数" },
        series: [
          // TODO
          {
            type: "line",
            lineStyle: { width: 2.5 },
            smooth: true,
            symbolSize: 0,
            data: [],
            name: "选择题",
            label: { show: false, position: "top" },
          },
          {
            type: "line",
            lineStyle: { width: 2.5 },
            smooth: true,
            symbolSize: 0,
            data: [],
            name: "判断题",
            label: { show: false, position: "top" },
          },
          {
            type: "line",
            lineStyle: { width: 2.5 },
            data: [],
            name: "编程题1",
            smooth: true,
            symbolSize: 0,
            label: { show: false, position: "top" },
          },
          {
            type: "line",
            lineStyle: { width: 2.5 },
            smooth: true,
            data: [],
            name: "编程题2",
            symbolSize: 0,
            label: { show: false, position: "top" },
          },
          // {
          //   type: "line",
          //   lineStyle: { width: 2.5 },
          //   smooth: true,
          //   symbolSize: 0,
          //   data: [],
          //   name: "第一题",
          //   label: { show: false, position: "top" },
          // },
          // {
          //   type: "line",
          //   lineStyle: { width: 2.5 },
          //   smooth: true,
          //   symbolSize: 0,
          //   data: [],
          //   name: "第二题",
          //   label: { show: false, position: "top" },
          // },
          // {
          //   type: "line",
          //   lineStyle: { width: 2.5 },
          //   data: [],
          //   name: "第三题",
          //   smooth: true,
          //   symbolSize: 0,
          //   label: { show: false, position: "top" },
          // },
          // {
          //   type: "line",
          //   lineStyle: { width: 2.5 },
          //   smooth: true,
          //   data: [],
          //   name: "第四题",
          //   symbolSize: 0,
          //   label: { show: false, position: "top" },
          // },
          // {
          //   type: "line",
          //   lineStyle: { width: 2.5 },
          //   smooth: true,
          //   data: [],
          //   name: "第五题",
          //   symbolSize: 0,
          //   label: { show: false, position: "top" },
          // },
          {
            type: "line",
            smooth: true,
            symbolSize: 0,
            data: [],
            name: "总分",
            label: {
              show: false,
              position: "top",
            },
          },
        ],
      };
      // console.log(that.staticData);
      this.$http
        .get(
          "/api/ranklist?" +
            q_string +
            (password ? "&password=" + password : "")
        )
        .then(function (res) {
          const N = 4; // TODO

          //console.log(res.data.data);
          that.desserts = res.data.data;
          that.totalDesserts = res.data.total;
          that.staticData.xAxis.data = [];
          for (var k = 0; k < N; k += 1) that.staticData.series[k].data = [];
          that.staticData.series[N].data = [];
          for (var i = 0; i < that.desserts.length; i++) {
            that.staticData.xAxis.data.push(i + 1);
            that.desserts[i].rank = i + 1 + (page - 1) * itemsPerPage;
            //console.log(that.desserts[i].details[0])
            for (var j = 0; j < N; j += 1) {
              var x = that.desserts[i].details[j];
              if (x) x = x.score;
              else x = 0;
              that.staticData.series[j].data.push(x);
            }
            var xx = that.desserts[i].total_score;
            that.staticData.series[N].data.push(xx);

            /*for (var j=0;j<=4;j+=1)
          {
            var x = that.desserts[i].details[j];
            if(x)
              x=x.score;
            if(!x||x.score==0)
              that.staticData.series[j].data[0]+=1;
            else if(x<=20)
              that.staticData.series[j].data[1]+=1;
            else if(x<=40)
              that.staticData.series[j].data[2]+=1;
            else if(x<=60)
              that.staticData.series[j].data[3]+=1;
            else if(x<=80)
              that.staticData.series[j].data[4]+=1;
            else if(x<100)
              that.staticData.series[j].data[5]+=1;
            else
              that.staticData.series[j].data[6]+=1;
          }

          var xx = that.desserts[i].total_score;
          if(!xx||xx.score==0)
            that.staticData.series[5].data[0]+=1;
          else if(xx<=100)
            that.staticData.series[5].data[1]+=1;
          else if(xx<=200)
            that.staticData.series[5].data[2]+=1;
          else if(xx<=300)
            that.staticData.series[5].data[3]+=1;
          else if(xx<=400)
            that.staticData.series[5].data[4]+=1;
          else if(xx<=500)
            that.staticData.series[5].data[5]+=1;
          else
            that.staticData.series[5].data[6]+=1;*/
          }
          for (var ii = 0; ii < N + 1; ++ii)
            that.staticData.series[ii].data.sort(function (a, b) {
              return b - a;
            });
          const myChart = echarts.init(
            document.getElementById("scoreStatistics")
          );
          myChart.setOption(that.staticData);
          //随着屏幕大小调节图表
          window.addEventListener("resize", () => {
            myChart.resize();
          });
          that.loading = false;
          //that.meta = res.data.meta;
        });
      setTimeout(this.getDataFromApi, 60 * 1000);
    },
    updateData() {
      const myChart = echarts.init(document.getElementById("scoreStatistics"));
      myChart.setOption(this.staticData);
    },
  },
};
</script>
