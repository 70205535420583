<template>
  <v-container>
    <v-row class="text-center aligh-center">
      <v-card class="mx-auto" width="90%">

        <v-row class="align-center" style="height:100px">
          <v-spacer />
          <!--v-img width="10%"  src="CCSP_Y.jpeg"/-->
          <v-spacer />
          <h1>{{ origin.name }}排行榜</h1>
          <v-spacer />
          <!--v-img width="10%"  class="mx-auto" src="MIAO.jpeg"/-->
          <v-spacer />
        </v-row>
        <!-- <v-card-text><v-text-field v-model="searchinfo" label="搜索" single-line hide-details></v-text-field></v-card-text> -->
        <v-data-table :headers="headers" :items="desserts" :search="searchinfo" :items-per-page="10"
          :options.sync="options" :server-items-length="totalDesserts" :loading="loading"
          :footer-props="{ 'items-per-page-options': [10, 100, -1] }" class="elevation-1">
          <template v-slot:item.rank="{ item }">
            <v-chip :color="getColor(item.total_score)" :dark="getDark(item.total_score)">
              {{ item.rank }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Ranklist",
  props: ['origin'],
  data: () => ({
    searchinfo: "searchinfo",
    totalDesserts: 1000,
    loading: true,
    options: {},
    headers: [
      {
        text: "排名",
        align: "start",
        sortable: false,
        value: "rank",
      },
      { text: "姓名", value: "realname" },
      // { text: "单位", value: "school" },
      { text: "总分", value: "total_score" },
      { text: "选择题", value: "p1" },
      { text: "判断题", value: "p2" },
      { text: "编程题1", value: "p3" },
      { text: "编程题2", value: "p4" },
      // { text: "第一题", value: "p1" },
      // { text: "第二题", value: "p2" },
      // { text: "第三题", value: "p3" },
      // { text: "第四题", value: "p4" },
      // { text: "第五题", value: "p5" },
    ],
    desserts: [],
  }),
  mounted: async function () {
    // await this.initSearch();
    await this.getDataFromApi();
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    origin: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    getColor(score) {
      // var rankInfo = [
      //   [0, 0, 0],
      //   [0, 0, 0],
      //   [0, 0, 0]
      // ]
      // if (rank <= rankInfo[origin][0]) return 'yellow'
      // else if (rank <= rankInfo[origin][1]) return 'grey'
      // else if (rank <= rankInfo[origin][2]) return 'brown'
      // else return 'white'
      if (score >= 90) return 'yellow'
      else if (score >= 75) return 'grey'
      else if (score >= 60) return 'brown'
      // if (score >= 400) return 'yellow'
      // else if (score >= 300) return 'grey'
      // else if (score >= 200) return 'brown'
      else return 'white'
    },
    getDark(score) {
      // var rankInfo = [
      //   [0, 0, 0],
      //   [0, 0, 0],
      //   [0, 0, 0]
      // ]
      // if (rank <= rankInfo[origin][2]) return true
      // else return false
      if (score >= 200) return true
      else return false
    },
    async getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const password = decodeURIComponent((new RegExp('[?|&]' + "password" + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || ["", ""])[1].replace(/\+/g, '%20')) || null;
      const q_string = "size=" + String(itemsPerPage) + "&page=" + String(page) + "&origin=" + String(this.origin.id);
      var that = this;
      this.$http.get("/api/ranklist?" + q_string + (password ? "&password=" + password : "")).then(function (res) {
        that.desserts = res.data.data;
        that.totalDesserts = res.data.total;
        for (var i = 0; i < that.desserts.length; i++) {
          //that.desserts[i].total_score = that.desserts[i].total_score;//.toFixed(6)
          that.desserts[i].rank = i + 1 + (page - 1) * itemsPerPage;
          //that.details[i].realname = that.details[i].realname.slice(0,1)+"***";
          that.desserts[i].p1 = that.desserts[i].details[0]?.score ?? "-";
          that.desserts[i].p2 = that.desserts[i].details[1]?.score ?? "-";
          that.desserts[i].p3 = that.desserts[i].details[2]?.score ?? "-";
          that.desserts[i].p4 = that.desserts[i].details[3]?.score ?? "-";
          // that.desserts[i].p5 = that.desserts[i].details[4]?.score ?? "-";
        }
        that.loading = false;
        //that.meta = res.data.meta;
      });
      setTimeout(this.getDataFromApi, 60 * 1000);
    },
  },
};
</script>
