<template>
  <v-app>
    <v-app-bar app color="{color}" dark>
      <div class="align-center">
        <!--h1>第27次CCF CSP编程培训师资认证</h1-->
        <!-- <h1>苏州市青少年C++编程挑战赛 2023年6月</h1> -->
        <h1>CCF GESP 2024年9月认证</h1>
        <!-- <h1>第6次 CCF PTA 认证</h1>  -->
        <!-- <h1>第34次 CCF CSP 认证</h1> -->
      </div>
      <v-btn text @click.stop="drawer = !drawer"><v-app-bar-nav-icon />切换</v-btn>
    </v-app-bar>
    <v-main>
      <v-container class="pa-0" style="height: 93vh; padding-top: 50px !important" fluid>
        <v-row align="start" justify="start" class="fill-height" fluid>
          <v-navigation-drawer class="pa-0 pt-2 pb-12 mt-12" v-model="drawer" fixed clipped style="min-width:60px; ">
            <v-list>
              <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title class="title">
                    切换
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider />

            <v-list nav dense>
              <v-list-item link v-for="o in origins" :key="o.origin"
                @click="() => (showstatic = 0, curOrigin = o, drawer = false)">
                <v-list-item-icon> <v-icon>mdi-star</v-icon> </v-list-item-icon>
                <v-list-item-title>{{ o.name }}排行榜</v-list-item-title>
              </v-list-item>

              <v-divider />

              <v-list-item link v-for="o in origins" :key="o.origin"
                @click="() => (showstatic = 1, curOrigin = o, drawer = false)">
                <v-list-item-icon>
                  <v-icon>mdi-star</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ o.name }}成绩分布</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
          <v-container class="fill-height ma-0 pa-0" style="overflow-y: auto; margin-left: 10% !important" fluid
            justify="start" align="start" no-gutter>
            <Ranklist v-if="!showstatic" :origin='curOrigin' />
            <Statistics v-if="showstatic" :origin='curOrigin' />
          </v-container>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Ranklist from "./components/Ranklist";
import Statistics from "./components/Statistics";
import { Watermark } from '@pansy/watermark';
const watermark = new Watermark({ text: "1970-01-01 00:00:00", blindFontSize: 40 });
watermark.show();
export default {
  name: "App",

  components: {
    Ranklist,
    Statistics,
  },

  data: () => ({
    drawer: true,
    curOrigin: { id: 0, name: "" },
    showstatic: 0,
    color: "#000000",
    color_min: [0x20, 0x20, 0x20],
    color_max: [0xE0, 0xE0, 0xE0],
    color_now: [0x20, 0x20, 0x20],
    color_dlt: [1, 1, 1],
    origins: [
      { id: 0, name: "" },
      { id: 1, name: "C++ 一级" },
      { id: 2, name: "C++ 二级" },
      { id: 3, name: "C++ 三级" },
      { id: 4, name: "C++ 四级" },
      { id: 5, name: "C++ 五级" },
      { id: 6, name: "C++ 六级" },
      { id: 7, name: "C++ 七级" },
      { id: 8, name: "C++ 八级" },
      { id: 9, name: "Python 一级" },
      { id: 10, name: "Python 二级" },
      { id: 11, name: "Python 三级" },
      { id: 12, name: "Python 四级" },
      { id: 13, name: "Python 五级" },
      { id: 14, name: "Python 六级" },
      //{ id: 16, name: "Python 八级" },
    ],
  }),
  methods: {
    updcolor() {
      var tim = new Date();
      watermark.update({ text: ["CCF GESP 202409", tim.toLocaleString()], fontSize: 24, gapX: 600, gapY: 300 })
      var app = document.querySelector('header');
      var idx = Math.floor(Math.random() * 3);
      this.color_now[idx] += this.color_dlt[idx];
      if (this.color_now[idx] > this.color_max[idx] || this.color_now[idx] < this.color_min[idx]) {
        this.color_dlt[idx] = -this.color_dlt[idx];
      }
      app.style.backgroundColor = "rgb(" + this.color_now[0] + "," + this.color_now[1] + "," + this.color_now[2] + ")";
    },
  },
  mounted: async function () {
    setInterval(this.updcolor, 50);
  },
};
</script>
